import axiosInstanceApiGateway from 'config/apiServiceConfig'

const { Get, Post, Put, Delete } = axiosInstanceApiGateway

export interface CartResponseInterface {
  data: CartDataType
  error: ErrorType
}

export interface AddToCartResponseInterface {
  data: UpdateAndAddCartDataType
  error: ErrorType
}

export interface UpdateCartResponseInterface {
  data: UpdateAndAddCartDataType
  error: Error
}

type UpdateAndAddCartDataType = {
  stock: number
  cart_type: string
  location_id: number
  price_components: CartItemPriceComponentType[]
  inventory_discount_next_tier_fmt: string
  product_id: number
  is_note_eligible: boolean
  limit_quantity: number
  bottom_sheet_next_page: string
  image_url: string
  pwp_campaign_id: number
  quantity: number
  price: number
  note: string
  price_astro_coin: number
  is_show_bottom_sheet: boolean
  name: string
  id: number
  attributes: CartAttributesType
}

type CartDataType = {
  carts: CartContentType[]
}

export type CartContentType = {
  location_type: string
  pwp_items: CartPWPItemType[]
  not_processed_pwp_items: CartPWPItemType[]
  total_earned_astro_coin_fmt: string
  items: CartItemType[]
  total_price_astro_coin: number
  cart_type: string
  not_processed_loyalty_items: CartLoyaltyItemType[]
  loyalty_special_items: CartLoyaltyItemType[]
  not_processed_items: CartItemType[]
  total_price_quickmart: number
  loyalty_items: CartLoyaltyItemType[]
  total_price: number
  total_price_astro_coin_fmt: string
  total_earned_astro_coin: number
}

type CartLoyaltyItemType = {
  product_id: number
  location_id: number
  is_special: number
  quantity: number
  stock: number
  name: string
  image_url: string
  quota: number
  coin: number
  coin_fmt: string
  note: string
  is_note_eligible: boolean
  limit_quantity: number
  total_volume: number
  product: CartProductType
}

export type CartItemType = {
  total_volume: number
  quantity: number
  limit_quantity: number
  stock: number
  product: CartProductType
  image_url: string
  total_earned_astro_coin: number
  product_id: number
  name: string
  id: number
  price_components: CartItemPriceComponentType[]
  total_saving: number
  earned_astro_coin: number
  note: string
  attributes: CartAttributesType
  inventory_discount: CartInventoryDiscountType
  inventory_discount_next_tier_fmt: string
  total_price: number
  paid_astro_coin: number
  is_note_eligible: boolean
}

type CartAttributesType = {
  modifier: {
    fmt: string
    modifier_variant_ids: number[]
    allow_variant_modifier: boolean
    missing_required_modifier: boolean
  }
  variant_product_ids: number[]
}

type CartPWPItemType = {
  product_id: number
  pwp_campaign_id: number
  quantity: number
  stock: number
  name: string
  image_url: string
  price_components: CartItemPriceComponentType[]
  total_price: number
  total_saving: number
  paid_astro_coin: number
  earned_astro_coin: number
  total_earned_astro_coin: number
  note: string
  is_note_eligible: boolean
  limit_quantity: number
  inventory_discount: CartInventoryDiscountType
  inventory_discount_next_tier_fmt: string
  total_volume: number
  product: CartProductType
  attributes: CartAttributesType
}

type CartProductType = {
  type: number
  length: number
  height: number
  width: number
  virtual_components: unknown[]
  weight: number
  volume: number
  bom_category: string
}

export type CartItemPriceComponentType = {
  quantity: number
  price_fmt: string
  component_type: string
  price: number
  component_title: string
  discount_price: number
  discount_price_fmt: string
  discount_percentage: number
  discount_percentage_fmt: string
  is_discounted: boolean
}

type CartInventoryDiscountType = {
  final_price: number
  discount_percentage: number
  daily_quota: number
  id: number
  discount_stock: number
  original_price: number
}

type ErrorType = {
  status: boolean
  code: number
  message: string
}

type AddToCartPayloadType = {
  cart_type: string
  product_id: number
  quantity: number
  is_loyalty_product: boolean
  loyalty_location_id: number
  pwp_campaign_id: number
  total_price_quickmart: number
  send_as_gift: boolean
  note: string | null
  source?: string
  bottom_sheet_current_page: string
  modifier_variant_ids: number[] | null
}

type UpdateCartPayloadType = {
  cart_type: string
  product_id: number
  quantity: number
  is_loyalty_product: false
  loyalty_location_id: number
  pwp_campaign_id: number
  total_price_quickmart: number
  send_as_gift: boolean
  note: string | null
  source?: string
  bottom_sheet_current_page: string
  modifier_variant_ids: number[] | null
  id?: number
}

type DeleteCartPayloadType = {
  productId: number
  params: { isloyalty: boolean; isSuper: boolean; isPwp: boolean }
}

type CartParamsType = {
  cart_types: string
  lite: boolean
  send_as_gift: boolean
}

type SummaryCartReqType = {
  order_total_price_item: number
  order_total_shipping: number
  order_voucher_id: string
  order_point_used: number
  order_payment_channel_id?: number
  hub_id?: number
  product_ids?: number[]
  order_payment_code?: string
  order_payment_channel?: string
  order_cc_number?: string
  minimum_duration?: number
  location_sla_id?: number
  order_redeem_point?: string
  platform_fee: number
  final_delivery_fee: number
  is_super: boolean
  order_type: string
  send_as_gift: boolean
  timing_id: number
  timing_name: string
  timing_type: string
}

type OrderCartReqType = {
  final_delivery_fee: number
  hub_id: number
  minimum_duration: number
  order_line_items: {
    order_item_inventory_discount_id: number | null
    order_item_product_id: number
    order_item_product_price: string
    order_item_product_quantity: number
  }[]
  order_note: string
  order_payment_channel: string
  order_payment_channel_id: number
  order_payment_code: string
  order_point_used: number
  order_total_coin: number
  order_total_coin_earned: number
  order_total_discount: number
  order_total_price: string
  order_total_shipping: string
  order_type: string
  order_voucher_id: string
  packaging_fee: number
  payment_fee: number
  send_as_gift: boolean
  service_level_id: number
  shown_gift_ids: number[]
  timeslot_id: number
  timing_id: number
  timing_name: string
  timing_type: string
}

export type SummaryCartResType = {
  total_payment: number
  total_point_used: number
  total_refund_point_used: number
  text_saving: string
  saving: number
  voucher_discount: number
  voucher_error: null
  shipping_cost: number
  discount_shipping_cost: number
  total_shipping_cost: number
  text_discount_shipping: string
  redeem_point_used: number
  is_slash_price: boolean
  is_force_use_ovo_point: null
  total_coins_earned: number
  shipping_cost_before_voucher: number
  voucher_discounts: string[]
}

type OrderCartResType = {
  normal_orders: {
    order_id: number
    order_status: string
    order_status_image_placeholder: string | null
    order_status_image: string | null
    order_status_late_image: string | null
    order_status_late_image_placeholder: string | null
    order_status_animation_text: string | null
    order_status_late_animation_text: string | null
    order_status_waiting_text: string | null
    order_driver: string | null
    order_customer_address: OrderCustomerAddressType
    order_invoice: string
    order_payment: OrderPaymentType
    order_line_items: OrderLineItemType[]
    order_note: string
    order_reason_cancel: string | null
    order_voucher: string | null
    order_total_price_item: string
    order_total_shipping: string
    order_original_shipping: string
    order_total_discount: string
    order_total_price: string
    order_point_used: number
    order_refund_point_used: number
    order_refund_point_used_fmt: string | null
    order_history_status: number[]
    order_tipping: string | null
    order_review: string | null
    financial_status: string
    order_total_coin: number
    order_earned_coin: number
    order_is_special: string | null
    order_id_special: string | null
    order_line_items_special: OrderLineItemType[]
    location_type: string | null
    order_status_vm_text: string | null
    late_ticker: string | null
    order_arrival_estimation: string | null
    platform_fee: PlatformFeeType
    order_sla: string | null
    super_order_timeslot_end: string | null
    order_type: string | null
    order_vouchers: string | null
  }

  special_orders: unknown
}
type OrderCustomerAddressType = {
  order_customer_address_id: number
  order_customer_address_service_level_id: number
  order_customer_address_service_total_duration: number
  order_customer_address_place_name: string
  order_customer_address_label: string
  order_customer_address_detail: string
  order_customer_address_desc: string
  order_customer_address_shipping_instruction: string
}

type OrderPaymentType = {
  payment_method: string
  payment_channel: string
  payment_ovo_number: string
  payment_image: string
}

type OrderLineItemType = {
  order_line_item_id: number
  order_item_product_id: number
  order_item_product_name: string
  order_item_product_image: string
  order_item_product_quantity: string
  order_item_product_price: string
  order_item_product_inventory_discount_id: number | null
  order_item_product_discount_price: number | null
  order_item_product_discount_quantity: number | null
  order_item_product_discount_daily_quota_label: string | null
  order_item_product_non_discount_quantity: number | null
  order_item_product_coin: number
  order_item_is_special: boolean | null
  order_item_price_fmt: string | null
  order_item_label: string | null
  product_modifier: string | null
  special_type: string | null
  ticket_detail: string | null
  modifiers: string | null
}

type PlatformFeeType = {
  payment_fee: number
  fmt_payment_fee: string
  is_enable_payment_fee: boolean
  packaging_fee: number
  fmt_packaging_fee: string
  is_enable_packaging_fee: boolean
  platform_fee_total: number
  fmt_platform_fee_total: string
  is_enable_platform_fee_total: boolean
}

interface CartDeleteParamsInterface {
  message: string
}

export const getCart = (query: CartParamsType) =>
  Get<CartResponseInterface, true>({
    url: `/cart/v1/carts`,
    params: query,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-API-VERSION': undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention, sonarjs/no-duplicate-string
        'X-APP-VERSION': 'ios-2.12.0',
      },
    },
  })

export const addToCart = (payload: AddToCartPayloadType) =>
  Post<AddToCartResponseInterface, true>({
    url: `/cart/v1/carts`,
    data: payload,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-API-VERSION': undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': undefined,
      },
    },
  })

export const updateCart = (payload: UpdateCartPayloadType) =>
  Put<UpdateCartResponseInterface, true>({
    url: `/cart/v1/carts`,
    data: payload,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-API-VERSION': undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': undefined,
      },
    },
  })

export const summaryCart = (data: SummaryCartReqType) =>
  Post<SummaryCartResType, true>({
    url: `/api/order/calculate`,
    data,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': 'ios-2.12.0',
      },
    },
  })

export const deleteCart = ({ productId, params }: DeleteCartPayloadType) =>
  Delete<CartDeleteParamsInterface, true>({
    url: `/api/cart/not-processed/${productId}`,
    params,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-API-VERSION': '1.9.8', // this is still needed since the endpoint is not from kong.
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': undefined,
      },
    },
  })

export const orderCart = (data: OrderCartReqType) =>
  Post<OrderCartResType, true>({
    url: `/api/order`,
    data,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-DEVICE-ID': '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-DEVICE-ADS-ID': '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-SESSION-ID': '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-App-Experiments': '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': 'ios-2.12.0',
      },
    },
  })
