import { toast } from 'react-hot-toast'
import { AxiosError } from '@astronautsid/wpe-utils/helpers/axiosIntance'

import { log } from '@astronautsid/wpe-utils/helpers/datadog'
import { useQuery, useMutation } from 'lib/reactQuery'
import { getCart, addToCart, updateCart, deleteCart } from 'utils/apiList/lite/cart'
import useUserStore from 'store/user'
import useCartStore from 'store/cart/slice'

type UseCartPropsType = {
  cartParams?: { cart_types: string; lite: boolean; send_as_gift: boolean }
  onErrorATCCallback?: () => void
  onErrorUTCCallback?: () => void
  silentError?: boolean
}

export default function useCart({
  cartParams,
  onErrorATCCallback,
  onErrorUTCCallback,
  silentError = false,
}: Partial<UseCartPropsType> = {}) {
  const { isLoggedIn, customerAddress, token } = useUserStore((state) => state)
  const { setCartMenuTotal, cartMenuTotal } = useCartStore((state) => state)

  const defaultParamsCart = {
    cart_types: `INSTANT`,
    lite: true,
    send_as_gift: false,
  }

  const defaultError = (error: AxiosError<{ error: { message: string } }>) => {
    // TODO: Add Logger Here if the logger is exist.

    const errMessage = error?.response?.data?.error?.message || error.message || 'Terjadi Kesalahan'
    toast.error(errMessage)
  }

  const {
    data: dataCart,
    isError: errorGetCart,
    refetch: refetchCartQuery,
    isLoading,
  } = useQuery({
    queryKey: ['cart', customerAddress.location_id],
    staleTime: 1000 * 60 * 5,
    enabled: isLoggedIn,
    queryFn: () => getCart(cartParams || defaultParamsCart),
  })

  const {
    mutateAsync: addToCartMutation,
    isError: errorAddToCart,
    isPending: isPendingAtc,
  } = useMutation({
    mutationKey: ['add-to-cart'],
    mutationFn: addToCart,
    onError: onErrorATCCallback || defaultError,
    onSuccess: () => {
      setCartMenuTotal(cartMenuTotal + 1)
      refetchCartQuery()
    },
  })

  const {
    mutateAsync: updateToCartMutation,
    isError: errorUpdateCart,
    isPending: isPendingUpdateCard,
  } = useMutation({
    mutationKey: ['update-to-cart'],
    mutationFn: updateCart,
    onError: onErrorUTCCallback || defaultError,
    onSuccess: (data) => {
      if (data?.data?.data?.quantity === 0) {
        setCartMenuTotal(cartMenuTotal - 1)
      }
      refetchCartQuery()
    },
  })

  const {
    mutateAsync: deleteCartMutation,
    isError: errorDeleteCart,
    isPending: isPendingDeleteCard,
  } = useMutation({
    mutationKey: ['delete-to-cart'],
    mutationFn: deleteCart,
    onError: onErrorUTCCallback || defaultError,
    onSuccess: () => {
      refetchCartQuery()
    },
  })

  // TODO: Please remove this comment if the logger is exist.
  // this was added to show that the silent error flow is to blocking the toast error only. not the logger.
  // eslint-disable-next-line sonarjs/no-collapsible-if
  if (errorGetCart) {
    // TODO: Add Logger Here if the logger is exist.

    log.info('Error get cart', {
      customInfo: {
        origin: 'code implementation',
        isLoggedIn,
        hasToken: !!token,
      },
    })

    if (!silentError) {
      toast.error('Failed to get cart data')
    }
  }

  const cartData = dataCart?.data?.data?.carts || []

  return {
    cartData,
    addToCartFunction: addToCartMutation,
    updateToCartFunction: updateToCartMutation,
    deleteCartFunction: deleteCartMutation,
    isErrorOnMutation: errorAddToCart || errorUpdateCart || errorDeleteCart, // in case need to handle error on the parent component.
    isPendingMutation: isPendingUpdateCard || isPendingAtc || isPendingDeleteCard,
    isLoadingCart: isLoading,
  }
}
